.single-sidebar-item-wrap {
    margin-bottom: 50px;
  
    &:last-child {
      margin-bottom: 0;
    }
  
    .sidebar-title {
      font-size: 18px;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 30px;
      margin-top: -3px;
  
      &:before {
        background-color: $paragraph;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 30px;
      }
    }
  }