/*===========================
29. Useful Links Page Style CSS
=============================*/

.table {
    > .row {
        > .col {
            > .row {
                @for $i from 1 through 12 {
                    > .col-#{$i} {
                        font-weight: bold;
                        padding: 0.5rem;
                        > .row {
                            @for $j from 1 through 12 {
                                > .col-#{$j} {
                                    font-weight: normal;
                                    overflow-x: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

